+responsive('max-width: 1280px')
  .wrapper
    .content
      .m-home &
        section.m-yellow
          .block
            &--in
              &:before
                left 30px
                top -70px

+responsive('max-width: 680px')
  .wrapper
    margin 0 auto
    height auto !important
    height 100%
    min-height 100%

    .content
      padding 0 0
      .m-home &
        section.m-yellow
          .block
            &--in
              &:before
                font-size 30px
                border-bottom 3px solid $white
                left 20px
                top -40px

  .footer
    margin-top 0
    height auto
