+responsive('max-width: 680px')
  .footer
    padding-bottom 10px
    padding-top 0

    .block--in
      display flex
      flex-direction column-reverse
      border none

    &--socials
      float none
      text-align center

      .socials--item
        margin 0 12px 0

        a
          font-size 18px

    &--copy
      border-top 1px solid #ffcc64
      padding-top 40px
      float none
      text-align center
      margin-bottom 20px
