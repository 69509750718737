+responsive('max-width: 980px')
  .form
    &--col
      select
        width 100%
+responsive('max-width: 680px')
  .form
    &--col
      &
      &.m-half
      &.m-third
        width 100%
        margin-right 0
        margin-top 20px

      &:first-child
        margin-top 0
    &.contact--form
      //max-width calc(100% - 60px)
      //max-width 80%
      margin auto
