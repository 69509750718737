*
  box-sizing border-box

body
  font-size 20px
  line-height 1.5
  color $dark_gray
  font-family $ff_base
  background $white

input
button
textarea
  font-family $ff_base
  font-size $fz

input[type="submit"]
button
  ibreset()

button
  cursor pointer
  &::-moz-focus-inner
  &::-moz-focus-inner
    border: 0
    padding: 0

a
  text-decoration none
  color $brand

  &:hover
    color lighten(@color, 30%)

em
i
  font-style italic

b
strong
  font-weight bold

img
  max-width 100%
  height auto

p
  margin 25px 0

h1
h2
h3
  font-weight normal

h1
  font-size 60px

h2
  font-size 40px

h3
  font-size 30px

figure
  margin 0 0 10px
  max-width 100%

figcaption
  text-align center
  color $dove_gray
  font-size 16px

.screen-reader-text
  display none
