.join-us
  .page_header
    .block
      &--in
        .block
          &--heading
          &--text
            max-width 510px

  .block
    &--title
      font-size 80px
      max-width none
  .our-culture
    .block--text
      display flex
      flex-flow wrap
      max-width none
      p
        flex: 0 0 50%
        color $dark_gray
        margin-top 0
        &:first-child
          font-size 35px
          font-weight 700
          line-height 50px
          padding-right 5%
        &:last-child
          font-size 20px
          font-weight 400
          line-height 30px
  .block
    &--text
      margin 0 auto 20px
      text-align left

  .perks,
  .job--details
    ul
      margin auto
      max-width 720px
      padding-bottom 60px
      li
        color $dark_gray
        font-size 20px
        position relative
        list-style none
        margin-bottom 20px
        &:before
          content ''
          display block
          position absolute
          left -70px
          top 0
          icon(check)
          height 30px
          width 30px

  .contact
    height auto
    min-height 50vh

.join_us
  &--location
    font-size .8em
    font-style italic
  &--job
    .block
      &--in
        .block
        &--heading
        &--text
          max-width 610px

  &--contact
    display flex
    flex-flow row nowrap
    justify-content space-between
    align-items center
    position relative
    background $brand
    color $medium_black
    margin auto
    max-width 1170px
    height 100px
    &:before
      content ''
      display block
      position absolute
      bottom 0
      left 0
      background-color $medium_black
      height 100%
      width 170px
    .icon-wrapper
      background-color $medium_black
      padding 30px 40px 20px
    .email
      &--icon
        width 90px
        height 40px
        margin-left 40px
        icon(email)

    p
      font-size 25px
      font-weight 700
      a
        color $medium_black
        text-decoration underline
        &:hover
          text-decoration none

    .btn
      margin-right 30px
