.project
  &
    position relative
    background-size cover
    background-position center
    background-repeat no-repeat
    padding 65px 0 45px
    cursor pointer

    &:before
      position absolute
      stretch()
      content ''

    .block--in
      position relative
      cf()

      &:after
        display inline-block
        content ''
        vertical-align middle
        width 1px
        height 575px

    &--block
      max-width 420px
      display inline-block
      vertical-align middle

    &--service
      aliasing()
      display block
      margin-bottom 20px
      font-family $ff_base
      text-transform uppercase
      font-size 14px
      letter-spacing 0.1em

    &--title
      font-family $ff_base
      font-size 60px
      line-height 1.1
      margin 0 0 20px -5px

    &--text
      font-weight 300
      aliasing()
      line-height 1.8
      font-size 18px
      letter-spacing 0.005em

      p
        margin 0 0 25px

  &.m-single
    cursor default

  &.m-white
    color $white

  &.m-bg_bluewood
    &:before
      background $bluewood

  &.m-bg_burgundy
    &:before
      background $burgundy

  &.m-bg_seawave
    &:before
      background $seawave

  &[class^='m-bg_']
  &[class*=' m-bg_']
    &:before
      opacity .85

  &.m-left
  &:nth-of-type(3n).m-left
  &:nth-of-type(3n+2).m-left
    .block--in
      text-align left

  &.m-left &
  &:nth-of-type(3n).m-left &
  &:nth-of-type(3n+2).m-left &
    &--block
      text-align left
      max-width 420px

  &.m-right
  &:nth-of-type(3n).m-right
  &:nth-of-type(3n+2)
    .block--in
      text-align right

  &.m-right &
  &:nth-of-type(3n).m-right &
  &:nth-of-type(3n+2) &
    &--block
      text-align left
      max-width 440px
      padding-right 20px

  &.m-center
  &:nth-of-type(3n)
  &:nth-of-type(3n+2).m-center
    .block--in
      text-align center

  &.m-center &
  &:nth-of-type(3n) &
  &:nth-of-type(3n+2).m-center &
    &--block
      text-align center
      max-width 560px

    &--title
      margin-left 0
