@keyframes fadeInUp {
  0% {
    opacity 0
    transform translate3d(0, 5%, 0)
  }

  100% {
    opacity 1
    transform none
  }
}

@keyframes fadeInUpSecondary {
  from {
    opacity 0
    transform translate3d(0, 100%, 0)
  }

  to {
    opacity 1
    transform translate3d(0, 0, 0)
  }
}

@keyframes fadeInFromNone {
  0% {
    fill rgba(43, 43, 43, .5)
  }

  100% {
    fill rgba(43, 43, 43, 1)
  }
}

@keyframes translateUp {
  from {
    transform translateY(0)
  }
  to {
    transform translateY(-1400px)
  }
}


@keyframes rotate {
  from {

    transform rotate(0deg)
  }
  to {
    transform rotate(360deg)
  }
}

@keyframes infiniteScroll {
  100% {
    background-position 0px -3000px
  }
}


@keyframes fadeInUpDefault {
  from {
    opacity 0
    -webkit-transform translate3d(0, 100%, 0)
    transform translate3d(0, 100%, 0)
  }

  to {
    opacity 1
    -webkit-transform translate3d(0, 0, 0)
    transform translate3d(0, 0, 0)
  }
}

.fadeInUpDefault
  animation-iteration-count 1
  animation-direction forwards
  animation-timing-function ease-in-out
  animation-name fadeInUpDefault
  animation-duration 0.5s


