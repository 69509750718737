.start
  background $brand
  text-align center
  color $white
  padding-bottom 80px

  &--title
    margin-bottom 5px

  &--text
    padding-bottom 10px
