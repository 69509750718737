+responsive('max-width: 980px')
  .capabilities
    padding 70px 0

    &--item
    &--item:nth-child(3n)
      width 48%
      margin 40px 4% 5px 0

      &:nth-child(2n)
        margin-right 0

+responsive('max-width: 680px')
  .capabilities
    padding 60px 0

    &--item
    &--item:nth-child(3n)
    &--item:nth-child(2n)
      width 100%
      margin 30px 0 0 0

      &:first-child
        margin-top 10px

+responsive('max-width: 480px')
  .capabilities
    padding 40px 0
