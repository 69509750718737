+responsive('max-width: 980px')
  .project_block
    &--text
      &--col
        &
          width 48%

        &:nth-child(2n)
          margin-left 4%

+responsive('max-width: 860px')
  .project_block
    &--text
      &--col
        &
          float none
          padding-top 20px
          width 100%

        &:first-child
          padding-top 0

        &:nth-child(2n)
          margin-left 0
