.btn
  background-color $brand
  color $white
  padding 17px 49px
  font-size 18px
  font-family $ff_base
  aliasing()
  display inline-block
  transition all .1s

  &:hover
    color $white
    background lighten(@background-color, 10%)

  &.m-white
    background $white
    color $brand

    &.m-frame
      color $white
      border-color @color

      &:hover
        background @color
        color $brand

  &.m-black
    font-family $ff_base
    font-weight 700
    background $dark_gray
    color $dark_gray
    text-transform uppercase

    &.m-frame
      color $dark_gray
      border-color $dark_gray
      letter-spacing 2px

  &.m-frame
    color $brand
    background none
    border $border_width = 3px solid @color
    padding 17px - $border_width 49px - $border_width

    &:hover
      background $dark_gray
      color $white

  &.m-has_loading
    &
      position relative

    i
      position absolute
      bottom 0
      height 4px
      left 0
      background darken($brand, 20%)
      content ''
      display block


.goback
  display flex
  text-transform uppercase
  font-size 13px
  align-items center
  line-height 13px

  &:before
    content ''
    @extend $icons
    @extend $icon-boxarrow_left_inversed__yellow
    margin-right 3px
