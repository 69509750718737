.teasers
    color $darkest_gray
    font-family $ff_base

    &--list
        display flex
        flex-wrap wrap
        align-items stretch

    &--item
        &
            width 32%
            border 3px solid $darkest_gray
            margin-right 2%
            margin-bottom 2%
            padding 30px 45px 55px
            padding-left 4%
            padding-right 4%
            cursor pointer
            transition all .1s
            aliasing()
            color $darkest_gray

        &:hover
            color $darkest_gray
            background-color $brand
            border-color $brand

        &:hover &
            &--title

            &--show
                opacity 1

        &:nth-child(3n)
            margin-right 0

        &--title
            display block
            text-transform uppercase
            font-size 25px
            font-weight 700
            margin-bottom 10px
            transition all .1s
            letter-spacing 0.02em

        &--text
            display block
            font-size 20px
            font-weight 400
            margin-bottom 20px
            letter-spacing 0.01em

        &--location
            font-size .8em
            font-style italic
            margin-bottom 20px

        &--show
            display block
            opacity 0
            transition all .1s
            font-size 18px
            font-weight 700
            text-transform uppercase
            color $darkest_gray
            letter-spacing 0.02em
