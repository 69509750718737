+responsive('max-width: 860px')
  .project_block
    &--title
      font-size 80px

    &.m-small &
      &--title
        font-size 60px

+responsive('max-width: 640px')
  .project_block
    &.m-mobile_hide
      display none

    &--title
      font-size 30px
      font-weight 300
      color $base
      opacity 1
      text-transform none

    &.m-small &
      &--title
        font-size 25px

    &--image
      span
        margin-top 20px

    &.m-has_bg
      padding-bottom 40px

    &--bg
      display none
