.m-about
  padding 100px 0

  &--text
    p
      font-size 20px
      padding-top 30px

  .svg-team
    display block
    &--background,
    &--foreground
      //opacity 0

    &--background-person
      &--body
        &--inner
          fill $white
        &--outer
          fill $brand

      &--face
        &--inner
          fill $white
        &--outer
          fill $brand

    &--foreground-person
      &--face
        fill $white
      &--contour
        fill rgba($dark_gray, 0.5)

    &.revealed
      .svg-team
        &--foreground-person
          &--contour
            animation(fadeInFromNone .7s ease)
            fill rgba($dark_gray, 1)

&--pic
    padding-top 5px
    max-width 940px
    margin 0 auto
    overflow hidden

