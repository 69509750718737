+responsive('max-width: 980px')
  .process
    padding 80px 0

    $icon_width = 100px

    &--steps
      padding-top 40px

      &--item
        &
          text-align center
          padding $icon_width + 20px 0 0
          max-width 500px
          margin 0 auto 50px

          &:after
            margin-top 0
            top 0
            left 50%
            margin-left -($icon_width / 2)

        &.m-arrow
          &:before
            display none

        &.m-right
          text-align center
          padding $icon_width + 20px 0 0

          &:after
            margin-right -($icon_width / 2)

        &.m-goals
          &:after
            margin-right -($icon_width / 2) - 8px

+responsive('max-width: 680px')
  .process
    padding 60px 0

    &--steps
      padding-top 20px

+responsive('max-width: 480px')
  .process
    padding 40px 0
