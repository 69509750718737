.contact
  .page_header
    .block
      &--in
        .block
          &--heading
          &--text
            max-width 620px
  .block
    &--in
      .m-home &
        width 100%

  &--title
    color $brand
    max-width 820px
  &--picker
    margin-bottom 100px
    display flex
    justify-content space-between
    &--label
      square(285px)
      background $light_gray
      cursor pointer
      transition all .2s
      text-align center
      display flex
      flex-direction column
      justify-content center
      font-size 20px
      font-weight 400
      font-family $ff_base
      aliasing()
      align-items center
      position relative
      &:before,
      &:after
        position absolute
        content ''
        margin auto
        left 0
        right 0

      &:before
        top 60px
        height 50px
        width 50px
        border 5px solid $lightest_gray

      &:after
        height 15px
        width 23px
        border-left 5px solid $medium_black
        border-bottom: 5px solid $medium_black
        transform: rotate(-45deg);
        top 75px

      &:hover
        box-shadow 0 0 30px rgba(0,1,1,.07)

    &--check
      display none
    &--check + &--label
      &:after
        content none
    &--check:checked + &--label
      background $brand
      &:before
        border-color $medium_black
      &:after
        content ''
      span
        font-weight 700

    &--title
      padding-top 80px
      position relative
      max-width 120px

  &--form
    .block
      &--title
        font-size 75px
        max-width none
      &--text--and--action
        max-width none
        padding-left 58%
      &--subtitle
        max-width 570px

    &--submit
      margin-left 210px
      min-width 190px
