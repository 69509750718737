.home_section
  &--text
    reset-offsets()
    font-size 20px
    font-weight 400
    color $dark_gray
    line-height 35px
    text-align left
    margin-bottom 20px
  .block
    &--in
      display flex
    &--title
      min-height 310px

  .section
    &--inner
      width 44%
      &:first-child
        padding-right 3%
      &:last-child
        padding-left 3%
  &.m-services
    strong
      display block
      margin-bottom 20px
      font-size 22px
      font-weight 700
      line-height 1.45em
