.project_block
  &--colors
    cf()

    &--item
      float left
      width 31%

      i
        display block
        height 95px


    &.m-2cols &
      &--item
        width 31%
        margin-left 3.5%

        &:first-child
          margin-left 0

    &.m-3cols &
      &--item
        width 31%
        margin-left 3.5%

        &:first-child
          margin-left 0

    &.m-4cols &
      &--item
        width 22.74%
        margin-left 3%

        &:first-child
          margin-left 0
