
$menu--dropdown
  li
    &.has-child > a
      &:after
        display inline-block
        transform translate(0, 0)
        @extend $icon-angle-down_fa
        margin-left 5px

      &:before
        position absolute
        display block
        width 100%
        height 20px
        bottom -20px
        left 0
        content ''

    &.has-child > .sub-menu
      z-index 5
      display none
      background $mgray
      min-width 160px
      max-width 215px
      text-align center
      position absolute
      padding 10px 10px
      borderbox()
      top 110%
      left 50%
      margin-left -(80px + 6px)

      &:before
        position absolute
        display block
        content ''
        width 0
        height 0
        border-style solid
        border-width 0 7.5px 7px 7.5px
        border-color transparent transparent $mgray transparent
        top -7px
        left 50%
        margin-left -7.5px

      li
        float none
        margin 0

        &:first-child
          margin-top 0

    &.has-child:hover > .sub-menu
      display block
      animation(fadeInUp .2s ease-in)

// useful
cut-text()
  overflow hidden
  white-space nowrap
  -ms-text-overflow ellipsis
  text-overflow ellipsis

cf()
  &:after
    content ""
    display table
    clear both

clearfix()
  &:after
    content ""
    display table
    clear both

ibreset()
  border 0
  padding 0
  margin 0
  outline none
  background none

t-translate()
  -ms-transform translate(arguments[0], arguments[1])
  -webkit-transform translate(arguments[0], arguments[1])
  transform translate(arguments[0], arguments[1])

transition()
  -webkit-transition arguments
  -moz-transition arguments
  -ms-transition arguments
  -o-transition arguments
  transition arguments

animation()
  -moz-animation arguments
  -webkit-animation arguments
  animation arguments

user-select()
  -webkit-user-select arguments
  -moz-user-select arguments
  user-select arguments

aliasing()
  text-rendering optimizeLegibility
  -webkit-font-smoothing antialiased

placeholder(n)
  &::-webkit-input-placeholder
    color n
    opacity 1

  &:-moz-placeholder
    color n
    opacity 1

  &::-moz-placeholder
    color n
    opacity 1

  &:-ms-input-placeholder
    color n
    opacity 1

icon(name)
  @extend $icons
  @extend $icon-{name}

reset-offsets()
  margin 0
  padding 0

reset-list()
  margin 0
  padding 0
  list-style-type none

// px to rem convert
rem()
  values = ()
  for i in arguments
    push(values, i/16 rem)
  return values

stretch()
  top 0
  left 0
  right 0
  bottom 0

// nice hide overflow content
fade-overflow(size = 10px)
  mask-image linear-gradient(to top, rgba(black 0) 0, black size)
  -webkit-mask-image -webkit-linear-gradient(to top, rgba(black 0) 0, black size)

// responsive
responsive(value)
	@media ( {value} )
		{block}

