.testimonials
  padding 100px 0 140px
  position relative

  &--list
    max-width 920px
    margin 0 auto
    text-align center
    font-weight 300
    font-style italic
    line-height 1.8

  &--item
    &--author
      font-family $ff_base
      font-style normal
      font-weight 300
      font-size 14px
      text-transform uppercase
      aliasing()
      display inline-block
      padding-top 15px
      letter-spacing 0.1em

      cite
        font-style normal

    p
      margin 0 0 20px

  .owl-dots
    display inline-block
