.navigation
  background $light_gray
  cf()

  &--arrow
    &
      width 50%
      float left

    &.m-prev
      text-align right

      i
        text-align left

        &:before
          icon(arrow_left)
          margin-right 10px

      &:first-child:last-child
        text-align left

    &.m-next
      float right

      i
        text-align right

        &:after
          icon(arrow_right)
          margin-left 10px

      &:first-child:last-child
        text-align right

    &:first-child:last-child
      width 100%
      float none

    a
      &
        font-size 18px
        text-transform uppercase
        font-weight 700
        font-family $ff_base
        display block
        color black
        transition all .1s
        letter-spacing 0.1em

      &:hover
        background $mystic
        color $brand

      i
        padding 70px 30px 69px
        display inline-block
        font-style normal
        max-width 490px
        width 100%

        &:before
        &:after
          vertical-align middle
          font-size 30px
          position relative
          top -2px
