+responsive('max-width: 1024px')
  .join_us
    &--contact
      display block
      height auto
      padding 15px 0 30px
      text-align center
      &:before
        width 130px
        height 70px
        right 0
        top 0
        bottom auto
        margin auto
      .email--icon
        display inline-block
        margin 0
      p
        padding 0 20px
      .btn
        margin-right 0


+responsive('max-width: 860px')
  .join-us
    .join_us
      &.our-culture
        .block
          &--copy
            display block
    .perks,
    .job--details
      ul
        li
          &:before
            height 20px
            width 20px
            left -40px
            top 5px
    .our-culture
      .block--text
        display block
        margin-bottom 0


+responsive('max-width: 680px')
  .join-us
    .page_header
      margin-bottom 0
    .join_us--job,
    .job--details
      .block
        &--title
          font-size 54px
    .our-culture
      .block--in
        .block--copy
          p
            font-size 25px
            &:first-child
              line-height 35px
    .perks
      ul
        padding-bottom 0

+responsive('max-width: 520px')
    .join_us
        padding-top 10px
