+responsive('max-height: 900px and min-width: 680px')
  .leader
    min-height 100vh
    padding 30px 0

+responsive('max-height: 800px')
  .leader
    &:before
      bottom -20%


@media (max-height: 500px) and (min-width: 1020px)
  .leader
    &:before
      bottom -42%

@media (max-height: 520px) and (min-width: 1280px)
  .leader
    &:before
      top 60%
      bottom auto

@media (max-height: 520px) and (min-width: 1580px)
  .leader
    &:before
      background-position 100% 50%

@media (max-height: 650px) and (min-width: 1020px)
  .leader
    &:before
      bottom -32%

@media (max-height: 670px) and (min-width: 1250px)
  .leader
    &:before
      top 52%
      bottom auto

@media (max-height: 670px) and (min-width: 1580px)
  .leader
    &:before
      background-position 100% 90%
      width 80%
      right 0

@media (min-height: 800px) and (min-width: 1580px)
  .leader
    &:before
      background-position 100% 100%
      width 90%
      right 0

+responsive('max-width: 1280px')
  .leader
    &--nav
      padding-top 30px

+responsive('max-width: 980px')
  .leader
    + .home_section
      &.m-fullheight
        padding-top 100px

+responsive('max-width: 860px')
  .leader
    &--intro
      &--title
        font-size 45px

+responsive('max-width: 680px')
  .leader
    &
      min-height 500px

    &.block.m-fullheight
      overflow: hidden
      min-height 620px

    &:before
      max-height 50%
      background-size 100% auto
      width 140%
      height 100%
      bottom 0
      left -20%

    &--intro
      display block
      padding-bottom 30px
      text-align left

      &--text
        margin-left 0
        margin-right 0
        max-width 350px
        font-size 20px
        text-align left

    &--scroll
      bottom -10px
      display: none

+responsive('max-width: 480px')
  .leader
    &
      min-height 0
      padding-top 0

      &:before
        max-height 40%
        background-size 100% auto
        width 200%
        height 100%
        bottom 0
        left -60%

    &--intro
      &--title
        font-size 34px
        line-height 1.55em

      &--text
        font-size 22px
        line-height 1.5em

    &--nav
      padding-top 10px
