+responsive('max-width: 980px')
  .page_header
    .block--in
      min-height 300px

+responsive('max-width: 860px')
  .page_header
    &--title
      font-size 45px

+responsive('max-width: 680px')
  .page_header
    .block--in
      min-height 0

    &--title
      font-size 35px

    &--text
      max-width 560px
      font-size 20px

    &--scroll + .block--in
      padding-bottom 40px


+responsive('max-width: 480px')
  .page_header
    padding 50px 0

    &--title
      font-size 24px

    &--text
      font-size 16px

    &--scroll + .block--in
      padding-bottom 20px
