.project_block
  &--numbers
    margin-bottom 60px
    cf()

    &--item
      width 31.3%
      float left
      margin-left 3.05%
      position relative
      text-align center
      background $white
      box-shadow 0 0 20px rgba(black, .07)
      line-height 1
      padding 90px 0 70px

      &:nth-child(3n+1)
        margin-left 0

      &--title
        font-size 52px
        aliasing()
        margin-bottom 50px

      &--text
        font-weight 300
        font-family $ff_base
        text-transform uppercase
        letter-spacing 0.1em
        font-size 16px
