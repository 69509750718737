.post
  &
    //border-top 1px solid
    //text-align center
    padding 40px 0
    aliasing()
    word-wrap break-word

  &.m-textleft
    text-align left

  &:first-child
    border-top none

  .block--in
    max-width 980px

  &--pic
    &
      max-width none
      display block
      margin-bottom 30px
      transition all .1s

  a&--pic
    &:hover
      opacity .9

  &--header
    //max-width 780px
    margin 0 auto

    &--title
      &
        font-weight 700
        font-size 50px
        color $base
        font-family $ff_base
        margin 0 0 25px
        line-height 1.3
        text-transform uppercase

      a
        color $brand
        transition color .1s

        &:hover
          color $brand

    &--meta
      font-weight 700
      text-transform uppercase
      margin-bottom 30px

  &--content
    font-family $ff_add
    margin 0 auto 30px
    font-size 20px
    font-weight 300
    line-height 1.8

  &--link
    &.m-readmore
      color $dark_gray
      border 3px solid $dark_gray
      padding 15px 40px
      text-transform uppercase
      font-weight 700
      font-size 20px
      display inline-block
      font-family $ff_add
      transition background .1s, color .1s

      &:hover
        background $dark_gray
        color $white
