.select2-container
    max-width 100%

    &--default
        .select2-selection
            &--single
                border 2px solid $light_gray2
                border-radius 0
                height 60px
                width 100%

                &:focus
                    outline none

                .select2-selection__rendered
                    line-height 42px
                    padding 9px 15px
                    font-family $ff_base
                    font-size 20px
                    font-weight 700
                    aliasing()
                    padding-right 40px

                .select2-selection__arrow
                    width 40px
                    height 100%

        .select2-results__option[aria-selected=true]
            background rgba($ghost .2)

        .select2-results__option--highlighted[aria-selected=true]
            color $base

.select2-container
    &--focus
    &--open
        .select2-selection--single
            border-color $medium_gray3

.select2-selection
    .select2-selection
        &--single
            border-radius 0
            height 42px

.select2-dropdown
    border-radius 0
    border 1px solid rgba($base .2)

.select2-results__option
    font-family $ff_base
    font-size 20px
    font-weight 700
    aliasing()
    line-height 1.2
    padding 10px 15px

.select2-container--open .select2-dropdown--below
    margin-top 1px
