.popup
    display none
    top 0
    left 0
    width 100%
    height 100%
    z-index 100
    overflow-y scroll
    position fixed
    background rgba($mirage .8)
    font-family $ff_base
    &.m-opened
        display block

    &.m-opened &
        &--container
            transform translateY(0%)
            opacity 1

        &--bg
            visibility visible
            opacity 1

    &--bg
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        visibility hidden
        opacity 0
        transition all 0.3s
        z-index 1

    &--close
        position absolute
        top 38px
        right 38px
        color $woodsmoke
        square(38px)
        text-align center
        transition all .1s
        line-height 45px

        &:before
            font-size 35px
            content '×'
            //icon(plugin_circle)

    &--container
        opacity 0
        padding 75px 115px
        // padding 5.4% 8.2%
        position absolute
        top 0
        left 50%
        background $white
        width 1008px
        margin-left -(@width / 2)
        z-index 2
        transform translateY(-200%)
        transition all .3s
        opacity 0

    &--meta
        reset-list()
        padding-bottom 3px
        line-height 1.3
        aliasing()

        li
            &
                margin-bottom 10px

            span
                font-size 14px
                text-transform uppercase
                color $iron
                display block

    &--title
        line-height 1.2
        margin 0 0 25px
        color $base
        font-size 40px
        letter-spacing 0.02em

    &--content
        //@extend .text
        font-size 20px
        letter-spacing 0.01em
        max-width 770px
        margin-bottom 10px

        h3
            font-weight bold
            color $base
            text-transform uppercase
            font-size 18px
            margin 0 0 20px
            padding-top 15px

        ul
            li
                &:before
                    top 5px

    &--actions
      text-align center
