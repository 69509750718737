+responsive('max-width: 980px')
  .iam
    padding 100px 0

    &:before
      width 65%

+responsive('max-width: 680px')
  .iam
  .block.m-half.iam
    &
      text-align left

    .block--title
    .block--text
      text-align left

  .iam
    &
      padding 50px 0

    &:before
      content none

+responsive('max-width: 480px')
  .iam
    &
      padding 35px 0
