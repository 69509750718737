.project_block
  &--chart
    cf()

    &--stats
      reset-offsets()
      float left
      width 25%
      list-style-type none

      &--item
        margin-top 40px
        padding-left 65px
        position relative
        font-family $ff_base
        font-size 14px
        font-weight 300
        text-transform uppercase
        letter-spacing 0.1em

        &:before
          position absolute
          icon(increase)
          color $brand
          top 3px
          left 0
          font-size 45px

        b
          color $brand
          font-size 36px
          display block
          font-family $ff_base
          font-weight normal
          margin-bottom 5px

    &--graph
      padding-top 55px
      float right
      width 75%
      text-align right
