.leader
  padding 0
  background $darkest_gray
  position relative
  min-height 500px
  color $white
  display flex
  flex-direction column
  padding-top 118px
  &:before
    content ''
    display block
    position absolute
    bottom -2px
    background url($imgsrc design/leader-background.png) no-repeat bottom center
    background-size contain
    height 100%
    width 100%
    transition-duration 0.3s

  .block--in
    position relative
    z-index 2
    display flex
    flex-direction column
    width 100%
    flex 1

  &--intro
    display flex
    flex-direction column
    align-items center
    padding-bottom 60px
    text-align center

    flex 1

    &--title
      font-size 50px
      color $white_secondary
      letter-spacing 0.01em
      margin-bottom 25px
      font-weight 700
      text-align left
      margin-top 0
      max-width 1000px
      line-height 1.2
      text-transform uppercase

      &.m-404
        font-size 0
        width 380px
        height auto
        min-width 220px
        max-width 50%

        &:before
          content ''
          display block
          background url($imgsrc design/404.png) no-repeat center center
          background-size cover
          width 100%
          height 0
          padding 36.027% 0 0


    &--text
      font-size 24px
      font-weight 400
      aliasing()
      max-width 880px
      margin 0 auto 50px
      line-height 1.6
      text-align left

      p
        reset-offsets()
        margin-bottom 30px

  &--scroll
    width 200px
    height 70px
    position absolute
    bottom 10px
    left 50%
    margin-left -(@width / 2)
    text-align center
    line-height 70px
    color $white
    transition all .2s

    &:hover
      opacity .7
      color $white

    &:before
      font-size 15px
      icon(expand_arrow)
      width 30px
      height 16px
