+responsive('max-width: 980px')
  .block
    &.services
      &.m-fullheight.m-yellow
        height auto
        .block--in
          .m-home &
            display block
            min-height auto
            padding-bottom 60px

     .block
       &--title
         max-width none

    .section
      &--inner

        &:first-child,
        &:last-child
          padding-left 0
          width 100%
          .services
            &--icon
              margin auto
          .block
            &--text
              max-width none
              padding-top 0       

    &--description
      &--item
        margin-bottom 50px
        display block

        &--title
          display block

+responsive('max-width: 680px')
  .services
    .block--title
      margin-bottom 40px
    &--icon
      height auto
      width 70%
    &--description
      &--item
        &
          margin-bottom 45px

          &--title
            margin-bottom 22px

        &:last-child
          margin-bottom 0
