.contact_info
  color $white
  position relative

  h2.block--title
    font-size 130px
    font-weight 700
    color $light_gray
    margin-bottom 0
    max-width none
    text-align center

  a
    color $dark_gray
    font-size 25px
    font-weight 700
    text-decoration underline
    transition all .1s
    position absolute
    top 50%
    left 50%
    margin-right -50%
    transform translate(-50%, -50%)

    &:hover
      text-decoration none
