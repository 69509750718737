+responsive('max-width: 1080px')
  .testimonials
    &--list
      padding 0 40px

+responsive('max-width: 680px')
  .testimonials
    padding 60px 0

    &--list
      padding 0

    .owl-dots
      display none
