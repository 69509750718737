+responsive('max-width: 1080px')
  .project_block
    &--numbers
      &--item
        &--title
          font-size 44px

+responsive('max-width: 960px')
  .project_block
    &--numbers
      text-align center

      &--item
        padding 70px 0 60px

        &--title
          margin-bottom 30px
          font-size 34px

+responsive('max-width: 860px')
  .project_block
    &--numbers
      &--item
        margin-bottom 30px
        float none
        display inline-block
        min-width 250px
