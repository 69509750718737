.owl-carousel
  position static

  .animated
    animation-duration 200ms

  .owl-item
    z-index 1

.owl-dots
  .owl-dot
    square(20px)
    text-align center
    line-height 13px
    margin-bottom 2px
    display inline-block

    span
      background $ghost
      display inline-block
      square(8px)
      border-radius 50%

    &.active
      span
        background $base
