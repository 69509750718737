.clients
  padding-bottom 0

  .block--title
    margin-bottom 15px

  &--text
    text-align center
    font-size 20px
    font-weight 300
    aliasing()
    margin-bottom 55px

  &--list
    display block
    text-align center
    reset-offsets()
    font-size 0
    padding-bottom 50px
    border-bottom 1px solid $geyser

  &--item
    display inline-block
    margin 0 14px 30px
    vertical-align middle
