/*********************
CSS3 GRADIENTS
*********************/
/* @include css-gradient(#dfdfdf,#f8f8f8); */
css-gradient($from = #dfdfdf, $to = #f8f8f8)
  background-color: $to
  background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to))
  background-image: -webkit-linear-gradient(top, $from, $to)
  background-image: -moz-linear-gradient(top, $from, $to)
  background-image: -o-linear-gradient(top, $from, $to)
  background-image: linear-gradient(to bottom, $from, $to)

/*********************
Placeholder Text
- Each browser needs its own definition.
*********************/
/* @include css-gradient(#dfdfdf,#f8f8f8); */
placeholder($color)
  &::-webkit-input-placeholder
    /* WebKit browsers */
    color: $color
  &::-moz-placeholder
    /* Mozilla Firefox 4 to 18 */
    color: $color
  &:-moz-placeholder
    /* Mozilla Firefox 19+ */
    color: $color
  &:-ms-input-placeholder
    /* Internet Explorer 10+ */
    color: $color

/*********************
BOX SIZING
*********************/
/* @include box-sizing(border-box); */
/* NOTE: value of "padding-box" is only supported in Gecko. So
probably best not to use it. I mean, were you going to anyway? */
box-sizing($type = border-box)
  -webkit-box-sizing: $type
  -moz-box-sizing: $type
  -ms-box-sizing: $type
  box-sizing: $type

/*********************
Columns
*********************/
columns($count, $gap = 50px, $color = transparent, $width = 3px)
  column-count($count)
  column-gap($gap)
  column-rule(3px solid $color)

column-count()
  -webkit-column-count arguments
  -moz-column-count arguments
  -ms-column-count arguments
  -o-column-count arguments
  column-count arguments

column-gap()
  -webkit-column-gap arguments
  -moz-column-gap arguments
  -ms-column-gap arguments
  -o-column-gap arguments
  column-gap arguments

column-rule()
  -webkit-column-rule arguments
  -moz-column-rule arguments
  -ms-column-rule arguments
  -o-column-rule arguments
  column-rule arguments

/*********************
 Shapes
*********************/
circle($radius, $color = black, $padding = 10px, $border-color = white, $border = 3px solid $border-color)
  height: $radius
  width: $radius
  text-align: center
  // max-height: ($radius * 1.1);
  // max-width: ($radius * 1.1);
  border-radius: ($radius * 3)
  line-height: $radius
  padding: $padding
  display: block
  border: $border
  background: $color
  overflow: hidden
  -webkit-background-clip: padding-box
  /* for Safari */
  background-clip: padding-box
/* for IE9+, Firefox 4+, Opera, Chrome */

square($d)
  height: $d
  width: $d

triangle($direction = down, $width = 20px, $height = $width, $color = black)
  width: 0
  height: 0
  border-left: setTriangleSize($direction, left, $width, $height) solid setTriangleColor($direction, left, $color)
  border-right: setTriangleSize($direction, right, $width, $height) solid setTriangleColor($direction, right, $color)
  border-bottom: setTriangleSize($direction, bottom, $width, $height) solid setTriangleColor($direction, bottom, $color)
  border-top: setTriangleSize($direction, top, $width, $height) solid setTriangleColor($direction, top, $color)

//Utility function to return the relevant colour depending on what type of arrow it is
setTriangleColor($direction, $side, $color)
  if $direction == left and $side == right
    $color
  else if $direction == right and $side == left
    $color
  else if $direction == down and $side == top
    $color
  else if $direction == up and $side == bottom
    $color
  else
    transparent

//Utility function to get the correct width/height depending on the type of arrow
setTriangleSize($direction, $side, $width, $height)
  if $direction == up and $side == left or $direction == up and $side == right or $direction == down and $side == left or $direction == down and $side == right
    $width / 2
  else if $direction == up and $side == top or $direction == up and $side == bottom or $direction == down and $side == top or $direction == down and $side == bottom
    $height
  else if $direction == left and $side == top or $direction == left and $side == bottom or $direction == right and $side == top or $direction == right and $side == bottom
    $height / 2
  else
    $width

responsiveChild($ratio = 9/16 , $child = 'iframe')

  position relative

  &:before
    content ""
    display block
    padding-top $ratio * 100%

  & {$child}
    position absolute
    top 0
    left 0
    width 100%
    height 100%
