.post
  &.m-single
    padding-top 35px

    .block--in
      max-width $block_width

  &.m-single &
    &--pic
      max-width: $post_single_width
      margin 0 auto 30px
    &--header
      max-width $post_single_width

    &--header--title
      color $dark_gray

    &--content
      font-size 20px
      max-width $post_single_width
      text-align left
      line-height 1.8

      h1, h2, h3, h4, h5
        font-family $ff_base
        font-weight 300
        margin 0 0 20px
        line-height 1.2

      h3
        padding-top 20px
        font-size 35px

      h4
        font-size 24px

      blockquote
        color $slate_gray
        font-size 32px
        font-style italic
        font-weight 300
        padding 0 5%
        text-align center

        p
          margin 0 0 20px

        cite
          display block
          font-size 14px
          font-family $ff_base
          font-weight 300
          font-style normal
          text-transform uppercase

      code, kbd, pre, samp
        overflow auto
        font-family $ff_base
