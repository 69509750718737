+responsive('max-width: 860px')
  .project
    &.m-mobile_overlay
      &:before
        background $white
        opacity .8

+responsive('max-width: 680px')
  .project
    padding 90px 0 60px

    &
    &.m-left
    &:nth-of-type(3n).m-left
    &.m-right
    &:nth-of-type(3n+2)
      .block--in
        text-align center

    &
    &.m-left &
    &:nth-of-type(3n).m-left &
    &.m-right &
    &:nth-of-type(3n+2) &
      &--block
        text-align center
        max-width 560px
        padding 0

    .block--in:after
      content none

    &--title
      font-size 45px
      margin-left 0

+responsive('max-width: 480px')
  .project
    padding 60px 0 30px

    &--service
      font-size 12px

    &--title
      font-size 32px
