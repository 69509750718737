.page_header
  background $darkest_gray
  background-image url($imgsrc design/job-background.svg)
  position relative
  color $white
  //text-align center
  aliasing()
  background-repeat no-repeat
  background-position center
  background-size cover
  padding 60px 0 80px
  margin-bottom 40px


  a
    color $white
    box-shadow 0px 2px 0px $white
    display inline-block

  &:before
    display block
    position absolute
    stretch()
    content ''
    opacity .85
    background $darkest_gray

  .block--in
    position relative
    z-index 2
    display flex
    flex-direction column
    min-height 355px
    justify-content center

  &--title
    reset-offsets()
    margin-bottom 15px
    font-size 80px
    text-transform uppercase
    line-height 1.2
    font-weight 700

  &--text
    font-size 20px
    font-weight 300
    font-style italic
    opacity .7
    line-height 1.8
    max-width 680px
    margin 0 auto

    p
      reset-offsets()
      margin-bottom 20px

    &--meta
      font-size 14px
      font-family $ff_base
      font-style normal
      text-transform uppercase
      display inline-block
      padding-top 10px

  &--scroll
    @extend .leader--scroll
    bottom 10px
