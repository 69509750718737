.project_block
  &--text
    cf()
    font-weight 300
    font-size 18px
    aliasing()
    line-height 1.8

    &--col
      &
        width 45%
        float left

      &:nth-child(2n)
        margin-left 10%

    &--title
      margin-top 0
      font-weight 300
      font-size 30px
      font-family $ff_base
      margin-bottom 25px
      line-height 1.5
