/* Generated by grunt-webfont */

/* Doesn't contain any output css, just mixins and variables */

 $icons
   display inline-block
   line-height 1
   font-weight normal
   background-position center center
   background-size contain
   background-repeat no-repeat
   content ''
   position relative
   width 16px
   height 16px


	$icon-angle-down_fa
	
		background-image inline-url('../icons/angle-down_fa.svg', 'utf8')
	

	$icon-arrow_left--box
	
		background-image inline-url('../icons/arrow_left--box.svg', 'utf8')
	

	$icon-arrow_left
	
		background-image inline-url('../icons/arrow_left.svg', 'utf8')
	

	$icon-arrow_readmore
	
		background-image inline-url('../icons/arrow_readmore.svg', 'utf8')
	

	$icon-arrow_right--box
	
		background-image inline-url('../icons/arrow_right--box.svg', 'utf8')
	

	$icon-arrow_right
	
		background-image inline-url('../icons/arrow_right.svg', 'utf8')
	

	$icon-boxarrow_left_inversed
	
		background-image inline-url('../icons/boxarrow_left_inversed.svg', 'utf8')
	

	$icon-boxarrow_left_inversed__yellow
	
		background-image inline-url('../icons/boxarrow_left_inversed__yellow.svg', 'utf8')
	

	$icon-boxarrow_right
	
		background-image inline-url('../icons/boxarrow_right.svg', 'utf8')
	

	$icon-check-circle
	
		background-image inline-url('../icons/check-circle.svg', 'utf8')
	

	$icon-check
	
		background-image inline-url('../icons/check.svg', 'utf8')
	

	$icon-check_picked
	
		background-image inline-url('../icons/check_picked.svg', 'utf8')
	

	$icon-circle_arrow_left
	
		background-image inline-url('../icons/circle_arrow_left.svg', 'utf8')
	

	$icon-circle_arrow_right
	
		background-image inline-url('../icons/circle_arrow_right.svg', 'utf8')
	

	$icon-code-lines
	
		background-image inline-url('../icons/code-lines.svg', 'utf8')
	

	$icon-delete
	
		background-image inline-url('../icons/delete.svg', 'utf8')
	

	$icon-digg
	
		background-image inline-url('../icons/digg.svg', 'utf8')
	

	$icon-email
	
		background-image inline-url('../icons/email.svg', 'utf8')
	

	$icon-expand_arrow
	
		background-image inline-url('../icons/expand_arrow.svg', 'utf8')
	

	$icon-facebook
	
		background-image inline-url('../icons/facebook.svg', 'utf8')
	

	$icon-google-plus
	
		background-image inline-url('../icons/google-plus.svg', 'utf8')
	

	$icon-googleplus
	
		background-image inline-url('../icons/googleplus.svg', 'utf8')
	

	$icon-home
	
		background-image inline-url('../icons/home.svg', 'utf8')
	

	$icon-increase
	
		background-image inline-url('../icons/increase.svg', 'utf8')
	

	$icon-linkedin-dark
	
		background-image inline-url('../icons/linkedin-dark.svg', 'utf8')
	

	$icon-linkedin
	
		background-image inline-url('../icons/linkedin.svg', 'utf8')
	

	$icon-love
	
		background-image inline-url('../icons/love.svg', 'utf8')
	

	$icon-pinterest
	
		background-image inline-url('../icons/pinterest.svg', 'utf8')
	

	$icon-pointer_arrow
	
		background-image inline-url('../icons/pointer_arrow.svg', 'utf8')
	

	$icon-quote
	
		background-image inline-url('../icons/quote.svg', 'utf8')
	

	$icon-rss
	
		background-image inline-url('../icons/rss.svg', 'utf8')
	

	$icon-scrollup
	
		background-image inline-url('../icons/scrollup.svg', 'utf8')
	

	$icon-search
	
		background-image inline-url('../icons/search.svg', 'utf8')
	

	$icon-stumbleupon
	
		background-image inline-url('../icons/stumbleupon.svg', 'utf8')
	

	$icon-twitter-dark
	
		background-image inline-url('../icons/twitter-dark.svg', 'utf8')
	

	$icon-twitter
	
		background-image inline-url('../icons/twitter.svg', 'utf8')
	

	$icon-consulting_circle
	
		background-image inline-url('../icons/consulting_icon/consulting_circle.svg', 'utf8')
	

	$icon-consulting_lifebuoy
	
		background-image inline-url('../icons/consulting_icon/consulting_lifebuoy.svg', 'utf8')
	

	$icon-consulting_lines
	
		background-image inline-url('../icons/consulting_icon/consulting_lines.svg', 'utf8')
	

	$icon-consulting_ropes
	
		background-image inline-url('../icons/consulting_icon/consulting_ropes.svg', 'utf8')
	

	$icon-dev_bg
	
		background-image inline-url('../icons/dev_icon/dev_bg.svg', 'utf8')
	

	$icon-dev_content
	
		background-image inline-url('../icons/dev_icon/dev_content.svg', 'utf8')
	

	$icon-dev_page
	
		background-image inline-url('../icons/dev_icon/dev_page.svg', 'utf8')
	

	$icon-plugin_circle
	
		background-image inline-url('../icons/plugin_icon/plugin_circle.svg', 'utf8')
	

	$icon-plugin_circle_large
	
		background-image inline-url('../icons/plugin_icon/plugin_circle_large.svg', 'utf8')
	

	$icon-plugin_frame
	
		background-image inline-url('../icons/plugin_icon/plugin_frame.svg', 'utf8')
	

	$icon-git
	
		background-image inline-url('../icons/social/git.svg', 'utf8')
	

	$icon-stackoverflow
	
		background-image inline-url('../icons/social/stackoverflow.svg', 'utf8')
	

	$icon-website
	
		background-image inline-url('../icons/social/website.svg', 'utf8')
	
