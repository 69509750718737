+responsive('max-width: 980px')
  .post
    &.m-single &
      &--content
        h3
          font-size 26px

        h4
          font-size 22px

        blockquote
          font-size 26px

+responsive('max-width: 680px')
  .post
    &.m-single
      border-top 1px solid $ghost
      padding-top 35px

    &.m-single &
      &--content
        font-size 16px

        h3
          font-size 23px

        h4
          font-size 20px

        blockquote
          font-size 23px

        ul
          padding-left 20px
