.services
  .block--in
    display: flex

  &--nav
    reset-offsets()
    font-family $ff_base
    font-weight 300
    font-size 16px
    text-transform uppercase
    margin 0 auto 65px
    padding-top 35px
    max-width 97%

    &--item
      &
        position relative
        margin-right 5%
        display inline-block
        color $bluewood
        font-family $ff_base
        font-size: 18px
        font-weight: 700
        opacity .3
        transition all .1s
        text-align left

        &:after
          position absolute
          bottom 0
          width 0
          height 2px
          background #000
          content ''
          display block
          left 0
          transition all .2s

      &:first-child
        margin-left 0

      &.m-active
      &:hover
        opacity 1

      &.m-active
        &:after
          width 100%

      &.m-plugins
        a
          &
            &:before
              icon(plugin_circle)
              color $white

            i:before
              icon(plugin_circle_large)
              color #C2CDDC

            i:after
              icon(plugin_frame)
              color #8592A5
              transition all .5s ease

          &:hover
            i:after
              transform rotate(180deg)

      &.m-consult
        a
          &
            &:before
              icon(consulting_ropes)
              color #8592A5
              z-index 1

            &:after
              icon(consulting_circle)
              color $white
              transition all .5s ease
              z-index 2

            i:before
              icon(consulting_lines)
              color #C2CDDC
              transition all .5s ease
              z-index 3

            i:after
              icon(consulting_lifebuoy)
              color #8592A5
              transition all .5s ease
              z-index 4

          &:hover
            &:after
            i:before
            i:after
              transform rotate(-90deg)

      &.m-dev
        a
          &
            &:before
              icon(dev_page)
              color #8592A5
              z-index 1

            i
              position absolute
              top 14px
              left 1px
              width 47px
              height 34px
              display block
              background $white
              overflow hidden

            i:before
              top 3px
              left -1px
              icon(dev_bg)
              color #C2CDDC
              font-size 59px
              z-index 3
              transition all .5s ease

            i:after
              top 3px
              left -1px
              icon(dev_content)
              color #8592A5
              font-size 59px
              z-index 4
              transition all .5s ease

          &:hover
            i:before
            i:after
              transform translateY(-31px)


      a
        color $bluewood
        position relative
        display inline-block
        padding 10px 0

        &:before
        &:after
        i:before
        i:after
          top 0
          left 0
          position absolute
          square(100%)
          content ''
          display inline-block
          font-size 50px

  &--description
    &--item
      padding-bottom 40px
      text-align left
      label
        color $dark_gray
        display inline-block
        font-size 35px
        font-weight 700
        margin-bottom 30px
      p
        font-size 20px
        line-height 30px

        &.m-dev
          &:before
            background-image url($imgsrc icons/dev.svg)
