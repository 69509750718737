+responsive('max-width: 980px')
  .post
    &--header
      &--title
        &
          font-size 35px

+responsive('max-width: 680px')
  .post
    &
      padding-top 0
      border-top none
      padding-bottom 100px

    &:first-of-type
      padding-top 50px

    &--pic
      margin-left -20px
      margin-right -20px
      margin-bottom 20px

    &--header
      &--meta
        margin-bottom 25px
        font-size 16px

      &--title
        font-size 30px
        margin-bottom 20px

    &--content
      font-size 18px

+responsive('max-width: 480px')
  .post
    &--header
      &--title
        font-size 25px
