.header
  background $darkest_gray
  padding 0
  position relative

  &--nav
    cf()
    border-bottom: 2px solid $brand
    padding 20px 0
    display flex
    flex-flow row nowrap
    justify-content space-between
    align-items center
    &:after
      display none

    &--menu
      reset-offsets()
      padding-top 12px
      margin-right -10px
      font-size 13px
      font-weight 700
      text-transform uppercase
      text-align center
      letter-spacing 0.1em

      li
        color: $brand
        display inline-block
        margin-left 5px

        a
          color $brand
          display block
          padding 10px
          transition all .1s

          &:hover
            color $white


    &--logo
      width 85px
      height 30px

    &--opener
      &
        position absolute
        right 15px
        top 20px
        display none
        transition all .1s
        square(40px)

        &:before
        &:after
        i
          position absolute
          top 9px
          left 50%
          width 24px
          height 2px
          margin-left -(@width / 2)
          content ''
          display block
          background $brand
          margin-bottom 7px
          transition all .3s
          transform-origin left center

        i
          top 18px

        &:after
          top 27px

      &:hover
        opacity .7
