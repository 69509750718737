+responsive('max-width: 980px')
  .home_section
    &.m-fullheight
      padding 60px 0 20px
    &.m-approach
      padding-bottom 40px
    &--icon
      display block
      margin 0 auto 20px
      max-width 80%
      height auto
      min-height 0
      transform translateY(0)
      &.svg-team
        margin-bottom 40px
      &.svg-galaxy  
        margin 40px auto
      &.svg-dev 
        margin -10px auto 10px

    &--intro
      font-size 22px
      line-height 1.45em
    .block
      &--title
        min-height 0
