.iam
  position relative
  padding 125px 0 110px

  &:before
    position absolute
    top 0
    right 0
    width 50%
    background url($imgsrc design/mystory-img@2x.jpg) no-repeat left center
    background-size cover
    content ''
    display block
    height 100%
    max-width 850px

  .block--in
    position relative
    z-index 2
