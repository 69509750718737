+responsive('max-width: 1080px')
    .popup
        &--container
            width 100%
            left 0
            margin-left 0

+responsive('max-width: 920px')
    .popup
        &--container
            padding 55px 75px

        &--title
            font-size 35px
            margin-bottom 20px

        &--close
            top 30px
            right 20px

            &:before
                font-size 25px

+responsive('max-width: 860px')
    .popup
        &--container
            padding 55px 35px

+responsive('max-width: 750px')
    .popup
        &--close
            right 15px

        &--container
            padding 80px 20px 50px

        &--title
            font-size 30px
            padding-top 10px
            margin-bottom 15px

        &--content
            ul
                line-height 1.45
                padding-bottom 10px

                li
                    margin-bottom 18px

+responsive('max-width: 480px')
    .popup
        &--close
            top 23px
            right 18px
            line-height 55px

            &:before
                font-size 48px
                line-height 23px
                content '×'
                aliasing()
                font-weight 300
                font-family $ff_base

        &--title
            font-size 24px

        &--actions
            text-align center
