.project_block
  &--quote
    font-size 24px
    color $white
    font-style italic
    text-align center
    font-weight 300
    aliasing()
    line-height 1.7
    padding-top 15px
    max-width 1000px
    margin 0 auto

    &:before
      display inline-block
      vertical-align middle
      height 400px
      content ''
      width 1px
      margin-right -0.4em

    blockquote
      display inline-block
      vertical-align middle

    span
      display block
      font-style normal
      text-transform uppercase
      font-family $ff_base
      font-size 14px
      padding-top 35px
      letter-spacing 0.1em
