+responsive('max-width: 680px')
  .m-about
    &--block
      display block
      padding-bottom 75px
    &--intro
      font-size 25px
    &--text
      p
        font-size 25px

+responsive('max-width: 480px')
  .m-about
    padding 60px 0 20px

    &--block
      padding-bottom 75px

    &--pic
      img
        width 600px
        max-width 600px
        margin-left -(@width / 2)
