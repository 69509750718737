html
body
  min-width $min_width
  height 100%

.wrapper
  margin 0 auto
  height auto !important
  height 100%
  min-height 100%

  .content
    padding 0 0 $footer_height

    .m-home &
      counter-reset section-counter
      section.m-yellow
        counter-increment section-counter
        .block
          &--in
            min-height 70vh
            position relative
            &:before
              content "0" counter(section-counter) ""
              display block
              position absolute
              top 5px
              left -70px
              font-size 35px
              font-weight 700
              color $white
              border-bottom 3px solid $white

.footer
  width 100%
  position relative
  margin-top - $footer_height
  height $footer_height
