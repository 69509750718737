.home_section
  &--icon
    min-height 350px
    transform translateY(-50px)
    &.svg
      &-galaxy
        overflow visible

.svg
  &-team
    margin-bottom 10px
    width 430px
    overflow visible !important

    .svgfill
      &.m-white
        fill #FFFFFF
      &.m-yellow
        fill:#FFCC64
      &.m-black
        fill:#2B2B2B

    .teammember
      opacity 0

      &.m-primary
        animation-delay 0.4s
      &.m-secondary
        animation-delay 0.8s
      &.m-tertiary
        animation-delay 1.2s
      &.m-quaternary
        animation-delay 1.6s
      &.m-quinary
        animation-delay 2s

      &.m-animated
        opacity 1

    &.revealed
      .teammember
        @extend .fadeInUpDefault

  &-galaxy
    width 410px
    &--sun
      fill $white

    .m-inner,
    .m-middle,
    .m-outer
      transform-origin center center
    .m-inner
      animation(rotate 50s linear infinite)
    .m-middle
      animation(rotate 100s linear infinite)
      animation-direction reverse
    .m-outer
      animation(rotate 100s linear infinite)
    .m-background
      fill $white
    .galaxy-details
      fill $dark_gray

  &-dev
    width 420px
    .m-background
      fill $white
    .programmer
      fill $dark_gray
    .code-line
      fill $brand
      animation(translateUp 35s infinite linear)

