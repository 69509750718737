+responsive('max-width: 480px')
  .navigation
    &--arrow
      &
        width 100%
        float none

      &.m-prev
        border-bottom 1px solid $ghost

      &.m-prev
      &.m-next
        i
          text-align center
