.project_block
  &--techs
    cf()

    &--item
      width 40%
      float left
      margin-right 3%
      position relative
      padding-left 90px
      margin-bottom 10px
      padding-top 20px

      &--icon
        position absolute
        top 0
        left 0
        height 75px
        width 65px
        display block
        background-repeat no-repeat
        background-size contain
        background-position center

      &.m-mongo &
        &--icon
          height 90px

      &--title
        font-family $ff_base
        font-size 24px
        font-weight 300

      &--text
        line-height 1.7
        color $chateau
        font-size 18px
        max-width 260px
        font-weight 300
