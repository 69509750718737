.footer
  background $brand
  padding 0
  padding-top 15px

  .block--in
    padding-top 20px

  &--socials
    float right
    position relative
    z-index 2

    .socials--item
      margin 0 0 0 15px
      a
        &.m-twitter
          transform translateY(2px) 

        color $medium_gray
        &:hover
          color $brand

  &--copy
    padding-top 5px
    display block
    color $medium_gray
    font-weight 400
    aliasing()
    font-size 15px
