.capabilities
  padding 90px 0

  &--list
    reset-offsets()
    cf()

  &--item
    reset-offsets()
    list-style-type none
    float left
    width 31%
    margin 55px 2.75% 5px 0
    font-weight 300

    &:nth-child(3n)
      margin-right 0

    &--title
      &
        position relative
        font-family $ff_base
        font-size 16px
        text-transform uppercase
        letter-spacing 0.1em
        margin-bottom 10px
        padding 10px 0 10px 45px

        &:before
          width 35px
          height 37px
          position absolute
          left 0
          top 2px
          vertical-align top
          content ''
          background-position center
          background-size contain
          background-repeat no-repeat

      &.m-plugins
        &:before
          background-image url($imgsrc icons/plugin.svg)

      &.m-consult
        &:before
          background-image url($imgsrc icons/consulting.svg)

      &.m-dev
        &:before
          background-image url($imgsrc icons/dev.svg)

      &.m-design
        &:before
          background-image url($imgsrc icons/visual-design.svg)

      &.m-mobile
        &:before
          background-image url($imgsrc icons/mobile.svg)

      &.m-content
        &:before
          background-image url($imgsrc icons/content.svg)

    &--text
      font-size 16px
      line-height 1.8

      p
        margin 0 0 20px
