.form
  &--row
    margin-bottom 20px
    word-spacing -0.25em
    font-size 0

    &.m-center
      text-align center

  &--col
    display flex
    flex-direction row-reverse
    justify-content flex-end
    margin-bottom 20px

    &.m-half
      width 48.5%
      margin-right 3%

      &:nth-child(2n)
        margin-right 0

    &.m-third
      width 31.5%
      margin-right 2.75%

      &:nth-child(3n)
        margin-right 0

  &--label
    margin-bottom 5px
    color $medium_gray2
    font-size 16px
    font-weight 700
    text-transform uppercase
    font-family $ff_base
    letter-spacing 0.05em
    min-width 210px
    padding-right 20px
    text-align right
    padding-top 18px
    letter-spacing 2px

  input
  textarea
  select
    min-width 620px
    border-radius 0
    background-color $white
    -webkit-appearance none
    -moz-appearance none
    border 2px solid $light_gray2
    font-size 20px
    font-weight 700
    color $dark_gray
    padding 15px
    font-family $ff_base
    transition border .2s
    resize none

    &:focus
      outline none
      border-color $medium_gray3

    &:focus + .form--label
      color black

    &.m-error
      input
      textarea
      .select2-container--default .select2-selection--single
      select
        border-color $red

    select
      background-image unquote('url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSI3cHgiIHZpZXdCb3g9Ijg5MS4zMzUgOTI1LjM3NSAyNSA3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05MDAuNjY3LDkyNS45NThjMCwwLjE1OC0wLjA1OCwwLjI5NS0wLjE3MywwLjQxbC00LjA4Myw0LjA4M2MtMC4xMTUsMC4xMTUtMC4yNTIsMC4xNzMtMC40MSwwLjE3M2MtMC4xNTgsMC0wLjI5NS0wLjA1OC0wLjQxLTAuMTczbC00LjA4My00LjA4M2MtMC4xMTUtMC4xMTUtMC4xNzMtMC4yNTItMC4xNzMtMC40MWMwLTAuMTU4LDAuMDU4LTAuMjk1LDAuMTczLTAuNDFjMC4xMTUtMC4xMTUsMC4yNTItMC4xNzMsMC40MS0wLjE3M2g4LjE2N2MwLjE1OCwwLDAuMjk1LDAuMDU4LDAuNDEsMC4xNzNDOTAwLjYwOSw5MjUuNjY0LDkwMC42NjcsOTI1LjgsOTAwLjY2Nyw5MjUuOTU4eiIvPjwvc3ZnPg==)')
      background-position right 50%
      background-repeat no-repeat

      &:-moz-focusring
        color transparent
        text-shadow 0 0 0 #000
