+responsive('max-width: 1180px')
  .contact
    &--picker
      &--block
        width 31%
      &--label
        square(265px)

+responsive('max-width: 1120px')
  .contact
    &--picker
      &--label
        width 100%
      &--title
        font-size 20px
        padding-top 120px

+responsive('max-width: 980px')
  .contact
    &--picker
      &--block
        width 31%

      &--label
        height 240px
        width 100%
    .form
      &--col
        flex-direction column-reverse
      &--label
        text-align left
      input,
      textarea,
      select
        min-width unset
        width 100%
    &--form
      &--submit
        margin-left 0
    .contact_info
      .block--title
        font-size 80px
        text-align center

+responsive('max-width: 860px')
  .contact
    &--picker
      margin-bottom 70px
      &--label
        letter-spacing 0

+responsive('max-width: 680px')
  .contact
    &--picker    
      flex-direction row
      align-items center
      flex-wrap wrap      

      &--block
        flex-basis 50%
        margin auto
        max-width 320px


      &--label
        height 220px
        span
          max-width none

    .block
      &--title &      
        font-size 45px
        line-height 1.4
      &--subtitle
        font-size 20px
        max-width none

    &.block.m-fullheight
      height auto

+responsive('max-width: 480px')
  .contact
    &--picker
      margin-bottom 30px
      &--title      
        font-size 14px

    &--form
      &--submit
        width 100%
    .contact_info
      .block--title
        font-size 60px
