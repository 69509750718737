+responsive('max-width: 1080px')
  .clients
    &--item
      margin 0 5px 20px
      width 23%

+responsive('max-width: 860px')
  .clients
    &--item
      height 100px
      width 100%
      text-align center
      padding 0 20px

      img
        position relative
        top 50%
        transform translateY(-50%)
        max-width 100%
        max-height 100%
        width auto !important
        height auto
        margin 0 auto

+responsive('max-width: 500px')
  .clients
    &--item
      margin 0
      height 80px
      padding 0
