+responsive('max-width: 1200px')
  .block
    &.contact-cta
      .block
        &--text--and--action
          margin 0

  .block--in
    .block
      &--title
        font-size 90px

+responsive('max-width: 980px')
  .block
    &.home_section &
      &--in
        display block
        padding-bottom 0

    &--title
      .contact &,
      .join-us &
        font-size 54px
        line-height 1.2em


    &.contact
      p
        font-size 20px
        line-height 1.4em

+responsive('max-width: 860px')
  .block
    &.m-fullheight
      height auto
    &:not(.leader)
      min-height 0
    .m-home &
      .block--in
        .block--text--and--action
          margin 0
        .block--text
          margin-bottom 0
          line-height 1.4em

+responsive('max-width: 680px')
  .block
    &
      padding 20px 0 0 0
    &.page_header
      padding 0 0 40px
      &--in
        display block
    &--in &
      &--title
        .m-home &
          font-size 70px
          line-height 1.2em
    &.contact-cta &
      &--title
        font-size 70px
        line-height 1.2em
        padding-right 0


    &.m-half &
      &--text
        width 100%
        max-width 100%
        text-align center

      &--title
        text-align center

    &--in
      padding $block_side_paddings - 10px
      .section--inner
        .block &
          padding 0
          width 100%

    &--title
      font-size 70px

      &.m-small
        font-size 24px

    &--subtitle
      margin-bottom 30px
    &.page_header
      .block
        &--heading
          font-size 55px
          line-height 60px

+responsive('max-width: 480px')
  .block
    &
      padding 20px 0

    &--title
      font-size 30px
      margin-bottom 20px
      .contact &,
      .join-us &
        font-size 40px
