+responsive('max-width: 960px')
  .project_block
    &--techs
      &--item
        width 47%

+responsive('max-width: 740px')
  .project_block
    &--techs
      &--item
        padding-left 70px
        padding-top 10px
        
        &--icon
          width 50px
          height 60px
