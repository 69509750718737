+responsive('max-width: 920px', 'min-width: 581px')
    .teasers
        &--item
            &
                width 48.5%
                //max-width 340px
                margin-right 3%
                padding 60px 20px

            &:nth-child(3n)
                margin-right 3%

            &:nth-child(2n)
                margin-right 0
+responsive('max-width: 680px')
  .teasers
    &--item
      &--text
        min-height 0
+responsive('max-width: 580px')
    .teasers
        padding-top 0

        .block--title.m-small
            margin-top 0

        &--item
            &
                width 100%
                margin-right 0
                max-width 100%
                margin-bottom 20px
                padding-left 15px
                padding-right 15px
                padding-bottom 30px

            &--show
                display none

            &:nth-child(3n)
                margin-right 0
