.socials
	reset-offsets()

	&--item
		display inline-block
		margin 0 3px 3px

		a
			&
				color $brand
				transition color .1s
				font-size 17px
				square(30px)
				display block
				line-height 30px
				text-align center

			&:hover
				color $white

			&.m-twitter
				&:not(.m-dark):before
					icon(twitter)
				&.m-dark
				  &:before
					  icon(twitter-dark)

			&.m-facebook
				&:before
					icon(facebook)

			&.m-linkedin
				&:not(.m-dark):before
					icon(linkedin)
				&.m-dark
				  &:before
					  icon(linkedin-dark)

			&.m-github
				&:before
					icon(git)

			&.m-stackoverflow
				&:before
					icon(stackoverflow)

			&.m-website
				&:before
					icon(website)

	&.m-square
		font-size 0

	&.m-square &
		&--item
			margin 0 10px 10px 0

			a
				display block
				square(35px)
				background $ghost
				color $bg
				text-align center
				line-height 40px
				transition all .1s

				&.m-linkedin
					&:before
						position relative
						top -1px

				&:hover
					background $slate_gray
