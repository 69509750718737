.project_block
  &
    position relative
    background-color $bg
    background-size contain
    background-repeat no-repeat
    background-position center bottom

  .block--in
    position relative
    z-index 2

  &--title
    font-size 120px
    opacity .1
    font-family $ff_base
    text-transform uppercase
    font-weight 800
    line-height 1
    margin 0 0 55px

  &--image
    span
      display block
      margin-top 40px
      position relative

      &:first-child
        margin-top 0

    .m-shadow
      box-shadow 0 0 50px rgba(black, .1)

    img
      vertical-align top

  &--bg
    position absolute
    bottom 0
    left 0
    right 0
    height 700px
    background-size cover
    background-position center
    background-repeat no-repeat
    display block
    mask-image linear-gradient(to bottom, rgba(0,0,0, 0) 0, black 400px)


  // Made with love block
  &.m-made
    padding-bottom 100px

  &--made
    text-align center
    font-weight 300
    font-style italic
    margin-bottom 40px
    color $slate_gray

    &:before
      icon(love)
      display block
      margin 0 auto 15px
      opacity .3
      font-size 45px

  // Social shares block
  &--shares
    text-align center

  // Image that fills all the block's width
  &.m-fullwidth
    overflow hidden
    padding-bottom 0

    img
      max-width none
      position relative
      left 50%
      transform translateX(-50%)

  // When we want the background to fill all the block
  &.m-fullbg &
    &--bg
      height 100%

  // Centering block's content
  &.m-center
    text-align center

  // When block has a background
  &.m-has_bg
    padding-bottom 470px

  &.m-has_bg.m-fullbg
    padding-bottom 70px

  // When we want the block to be shifted to the top a bit
  &.m-shifted
    margin-top -200px
    padding-top 250px

  // Block with images in mixed order positions
  &.m-mixed_images &
    &--image
      cf()

      span:nth-child(2)
        float right
        margin-top -370px

  // Dark color scheme
  &.m-dark
    background-color $bright_gray

  &.m-dark &
    &--title
      color $white
      opacity .05

  // White color scheme
  &.m-white
    background-color $white

  // Toning block's background
  &[class^='m-bg_']
  &[class*=' m-bg_']
    position relative

    &:before
      opacity .85
      position absolute
      stretch()
      content ''
      z-index 2

    .block--in
      position relative
      z-index 3

  &.m-bg_bluewood
    &:before
      background $bluewood

  &.m-bg_burgundy
    &:before
      background $burgundy

  &.m-bg_seawave
    &:before
      background $seawave

  // Smaller the block's title
  &.m-small &
    &--title
      font-size 80px
