.swal2-overlay
  background-color rgba($bluewood, .85)

.swal2-modal
  border-radius 0

  h2
    font-family $ff_base
    font-size 30px
    font-weight 300
    color $base

.swal2-content
  font-style italic
  font-size 18px
  font-weight 300
  font-family $ff_base
  color $slate_gray
  aliasing()
  max-width 420px
  margin 0 auto

  p
    font-size 18px

.swal2-confirm
  @extend .btn
  min-width 250px
