.text
    font-size 24px
    font-weight 300
    aliasing()
    margin-bottom 25px
    cf()

    ul
        reset-list()
        text-align left
        font-size 20px
        line-height 1.3

        li
            &
                position relative
                padding-left 33px
                margin-bottom 23px

            &:before
                position absolute
                icon(check-circle)
                top 6px
                left 0
                color $brand
                font-size 16px


    p
        margin 0 0 25px
