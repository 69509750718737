+responsive('max-width: 740px')
  .header
    &--nav
      &--menu
        li
          margin-left 0

+responsive('max-width: 680px')
  .header
    padding 0
    .block
      &--in
        padding 0 20px
    &--socials
      visibility hidden    

    &--nav
      padding 20px 0
      &
        &:before
          position absolute
          stretch()
          content ''
          display none
          background $darkest_gray

        &--menu
          display none
          float none
          margin-right 0
          font-size 20px
          aliasing()

          li
            margin-left 0
            display block
            text-align center
            margin-bottom 10px

        &--opener
          display block

      &.m-opened
        min-width $min_width
        position fixed
        overflow auto
        stretch()
        top 0
        margin-top 0
        display block
        z-index 5
        padding 0 20px
        border-bottom none

        &:before
          display block

      &.m-opened &
        &--menu
          display block
          position relative
          border-bottom 2px solid #ffcc64
          padding-top 42px
          li
            padding 20px 0
            border-bottom 1px solid rgba(238, 238, 238, 0.4)
            &:first-child
              border-top 2px solid #ffcc64
              margin-top 30px
              padding-top 30px
            &:last-child
              border-bottom none
              padding-bottom: 40px
            a
              color $brand

              &:hover
                color $white
          .socials
            visibility visible
        &--logo
          display block
          border-bottom 1px solid #ffcc64
          &:before
            position absolute
            left 20px
            top 22px

        &--opener
          top 20px
          &:before
          &:after
          i
            background $brand

          &:before
            transform rotate(45deg)

          &:after
            margin-top -1px
            transform rotate(-45deg)

          i
            width 0
            opacity 0
  .m-opened
    .socials
      padding-top 20px
      text-align center
      visibility visible
      li.socials--item
        a
          margin 10px
          &:before
            height 25px
            width 25px
