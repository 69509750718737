.process
  padding 95px 0 115px

  $icon_width = 100px

  &--steps
    padding-top 65px

    &--item
      &
        margin-bottom 55px
        padding-right 60%
        text-align right
        font-weight 300
        position relative

        &:after
          top -30px
          square($icon_width)
          display block
          content ''
          position absolute
          left 50%
          margin-left -(@width / 2)
          background-repeat no-repeat
          background-size contain

      &.m-arrow
        &:before
          icon(pointer_arrow)
          transform scaleX(-1)
          position absolute
          font-size 138px
          left 58%
          color $brand
          top 60px

      &.m-arrow.m-right
        &:before
          transform scaleX(1)
          left auto
          right 58%

      &.m-right
        padding-left 60%
        padding-right 0
        text-align left

        &:after
          left auto
          right 50%
          margin-right -($icon_width / 2)

      &--title
        font-size 28px
        font-family $ff_base
        margin-bottom 15px

      &--text
        font-size 18px
        aliasing()
        line-height 1.8

        p
          margin 0 0 20px

      &.m-goals
        &:after
          background-image url($imgsrc icons/goals.svg)

      &.m-strategy
        &:after
          background-image url($imgsrc icons/strategy.svg)

      &.m-team
        &:after
          background-image url($imgsrc icons/team.svg)
          margin-top 10px

      &.m-create
        &:after
          background-image url($imgsrc icons/create.svg)
          margin-top 15px
