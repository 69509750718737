.shares
  reset-offsets()
  font-size 0
  aliasing()
  margin-bottom 20px

  &--item
    &
      font-size 18px
      display inline-block
      margin-right 30px

    &:last-child
      margin-right 0

    &.m-fb
      a
        background $facebook-color

        &:hover
          background lighten(@background, 10%)

    &.m-tw
      a
        background $twitter-color

        &:hover
          background lighten(@background, 10%)

    a
      display block
      height 60px
      min-width 255px
      text-align center
      color $white
      padding 16px 10px
      font-family $ff_base
      transition background .1s
