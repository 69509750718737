+responsive('max-width: 1370px')
  .m-approach
    padding 80px 0 0
    .block
      &--text
        margin-bottom 30px
        text-align left
    &--icon
      position relative
      display block
      left 0
      right 0
      margin auto
      overflow hidden

+responsive('max-width: 980px')
  .m-approach
    .approach
      &--icon
        display block
        position relative
        max-width 400px
        left 0
        margin auto
      &--text
        max-width none

+responsive('max-width: 680px')
  .approach
    padding 0

    &:before
      content none
    .block--in
      padding-bottom 0
