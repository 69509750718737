.block
  padding 20px 0

  &.m-fullheight
    min-height 100vh
    &.leader
      min-height calc(100vh - 75px)
  &.m-gray
    background $bg
  &.m-yellow
    background $brand

  &.contact-cta
    display flex
    align-items center
    justify-content center

    .block
      &--in
        width 100%
      &--text--and--action
        margin-left 45%
        margin-top -140px
        max-width 380px   

  .contact
    &--title
      .m-home &
        max-width 820px
      .join-us &
        padding-right 20%

  &--in
    max-width $block_width
    padding 0 $block_side_paddings
    margin 0 auto

  &.page_header &
    &--heading
    &--text
      color $white_secondary
      margin 0
      text-align left
    &--heading
      font-size 80px
      font-weight 700
      line-height 100px
      text-transform uppercase
    &--text
      font-size 24px
      font-weight 400
      line-height 1.3em

  &--title
    reset-offsets()
    color $white
    font-weight 700
    margin-bottom 45px
    font-family $ff_base
    text-align left
    font-size 120px
    text-transform: uppercase
    max-width 570px
    aliasing()
    .m-home &
      max-width 620px
      line-height 130px

    &.m-small
      font-size 30px

    &.m-bold
      font-weight normal
    &.m-yellow
      color $brand

  &--subtitle
    font-size 20px
    font-weight 400
    color $dark_gray
    margin-bottom 50px

  &--title + &--subtitle
    margin-top -10px

  &--text
    max-width 720px
    margin 0 auto
    font-size 20px
    font-weight 300
    text-align center
    aliasing()
    @extend .text
    line-height 1.8

    &.m-small
      font-size 18px

    p
      reset-offsets()
      font-size: 25px
      font-weight: 400
      color $dark_gray
      line-height: 35px
      text-align: left
      margin-bottom 20px
  &--intro
    color $dark_gray
    font-size 28px
    font-weight 700
    line-height 50px
    margin-bottom 30px

  &.m-left &
    &--title
      text-align left
      margin-bottom 20px

    &--text
      max-width 100%
      text-align left
      margin-left 0
      letter-spacing 0.007em

      p
        margin-bottom 30px

  &.m-half &
    &--text
      max-width 490px
      width 50%
